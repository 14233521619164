(function ($) {
    'use strict';

    $.navigation = new Object({
        init: function () {
            const burgerToggle = document.querySelector('.page-header__navigation-toggle');
            const mainNav = document.querySelector('#main-navigation .navigation');
            const navItems = mainNav.querySelectorAll('li');
            const navigationWrapper = document.querySelector('.page-header__navigation');
            const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

            burgerToggle.addEventListener('click', () => {
                navigationWrapper.classList.toggle('open');
                toggle(navigationWrapper);
                burgerToggle.ariaExpanded = burgerToggle.ariaExpanded !== 'true';
            });

            navItems.forEach((item) => {
                const subnav = item.querySelector('.subnav');
                const subnavToggle = subnav?.previousElementSibling.querySelector('.subnav-toggle');
                const level = subnavToggle?.dataset.subnavLevel;

                subnavToggle?.addEventListener('click', () => {
                    setSubnav(subnav, level);
                });
            });

            function setSubnav(subnav, level) {
                const id = subnav.id;
                const subnavToggle = subnav?.previousElementSibling.querySelector('.subnav-toggle');
                const openNavs = document.querySelectorAll(
                    `.subnav-level-${level}.open:not([id=${id}]), .subnav-level-${level}.open:not([id=${id}]) .subnav.open, .subnav-level-${level}.open[id=${id}] .subnav.open`,
                );

                if (openNavs.length > 0) {
                    closeOpenMenus(openNavs);
                }

                toggle(subnav);
                subnav.classList.toggle('open');
                subnavToggle.ariaExpanded = subnavToggle.ariaExpanded !== 'true';
            }

            function closeOpenMenus(openNavs) {
                openNavs.forEach((nav) => {
                    const subnavToggle = nav.previousElementSibling.querySelector('.subnav-toggle');
                    toggle(nav, true);
                    nav.classList.remove('open');
                    subnavToggle.ariaExpanded = 'false';
                });
            }

            document.addEventListener('keyup', (e) => {
                if (e.key === 'Escape') {
                    closeOpenMenus(document.querySelectorAll('.subnav.open'));
                    toggle(navigationWrapper, true);
                    navigationWrapper.classList.remove('open');
                    burgerToggle.ariaExpanded = 'false';
                }
            });

            window.addEventListener('resize', () => {
                closeOpenMenus(document.querySelectorAll('.subnav.open'));
                toggle(navigationWrapper, true);
                navigationWrapper.classList.remove('open');
                burgerToggle.ariaExpanded = 'false';
            });

            document.addEventListener('click', (e) => {
                if (!$(e.target).closest('header').length && !$(e.target).is('header')) {
                    closeOpenMenus(document.querySelectorAll('.subnav.open'));
                    toggle(navigationWrapper, true);
                    navigationWrapper.classList.remove('open');
                    burgerToggle.ariaExpanded = 'false';
                }
            });

            function toggle(el, close) {
                const isMobile = window.screen.width < 991;

                if (close) {
                    el.style.height = '0px';
                    el.style.display = 'none';

                    if (!isMobile) {
                        el.style.removeProperty('display');
                        el.style.removeProperty('height');
                    }
                    return;
                } else {
                    if (!isMobile) {
                        el.style.removeProperty('display');
                        el.style.removeProperty('height');
                        return;
                    }
                }

                if (el.style.display === 'none' || el.style.height === '') {
                    el.style.display = 'block';
                    const targetHeight = `${el.scrollHeight}px`;

                    if (!prefersReducedMotion) {
                        el.style.height = '0px';

                        setTimeout(() => {
                            el.style.height = targetHeight;
                        }, 0);

                        el.addEventListener(
                            'transitionend',
                            function () {
                                el.style.height = 'auto';
                            },
                            { once: true },
                        );
                    } else {
                        el.style.height = targetHeight;
                    }
                } else {
                    el.style.height = `${el.scrollHeight}px`;
                    setTimeout(() => {
                        el.style.height = '0px';
                    }, 0);

                    if (prefersReducedMotion) {
                        el.style.height = '0px';
                    }

                    el.addEventListener(
                        'transitionend',
                        function () {
                            el.style.display = 'none';
                        },
                        { once: true },
                    );
                }
            }
        },
    });
})(jQuery);
